import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteDialogService {

  visible = false;
  listener = new Subject<boolean>();

  constructor() { }

  present() {
    this.visible = true;
    return new Promise((resolve, reject) => {
      this.listener.subscribe(button => {
        if (button) {
          resolve();
          this.dismiss();
        } else {
          reject();
          this.dismiss();
        }
      });
    });
  }

  dismiss() {
    this.visible = false;
  }

  accept() {
    this.listener.next(true);
  }

  decline() {
    this.listener.next(false);
  }
}
