import { AuthService } from './../../services/auth.service';
import { ApiService } from './../../services/api.service';
import { Subscription } from 'rxjs';
import { UsuarioService } from './../../services/class/usuario.service';
import { HelperService } from './../../services/helper.service';
import { AlertService } from 'ngx-alerts';
import { Usuario } from './../../models/usuario.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-usuarios-editar',
  templateUrl: './usuarios-editar.component.html',
  styleUrls: ['./usuarios-editar.component.scss']
})
export class UsuariosEditarComponent implements OnInit {

  usuario: Usuario = new Usuario();
  loading: boolean = false;
  show: boolean = false;

  buscarUsuarioSubscription: Subscription;

  file: File;
  progress: number = 0;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public alertService: AlertService,
    public helper: HelperService,
    public usuarioService: UsuarioService,
    public api: ApiService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.buscarUsuario(param.id);
    });
  }

  ngOnDestroy() {
    this.buscarUsuarioSubscription.unsubscribe();
  }

  buscarUsuario(id) {
    this.buscarUsuarioSubscription = this.usuarioService.buscarById(id)
      .subscribe((res: any) => {
        this.usuario = res;
      })
  }

  alterarUsuario(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.usuarioService.alterar(this.usuario)
      .subscribe((res: any) => {
        this.submitImages(this.file, '/usuario/imagemPerfil?id=' + this.usuario.id);
        if (this.usuario.id == this.auth.user.id) {
          this.auth.user = this.usuario;
          this.auth.setUser(this.usuario);
        }
        this.loading = false;
        this.router.navigate(['/usuarios/geral']).then(() => {
          this.alertService.success('Usuário alterado com sucesso.');
        })
      }, e => {
        this.alertService.danger(e.error.message);
        this.loading = false;
      });
  }

  @ViewChild('file', { static: true }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImages(this.file, '/usuario/imagemPerfil?id=-99').then((res: any) => {
      this.usuario.imagemPerfil = res.body;
    }).catch(() => {
      this.progress = 0;
      this.inputFile.nativeElement.value = '';
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.usuarioService.postImage(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

}
