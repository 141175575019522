import { Mensagem } from './../../models/mensagem.model';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  constructor(
    private api: ApiService
  ) { }

  buscar(page, filter?: string): Observable<any> {
    if (!filter) {
      return this.api.get('/contatoMensagem/buscar?numeroPagina=' + page);
    } else {
      return this.api.get('/contatoMensagem/buscar?filtro=' + filter + '&numeroPagina=' + page);
    }
  }

  buscarById(id): Observable<any> {
    return this.api.get('/contatoMensagem/buscar?id=' + id);
  }

  inserir(mensagem: Mensagem): Observable<any> {
    return this.api.post('/contatoMensagem/inserir', mensagem);
  }

  alterar(mensagem: Mensagem): Observable<any> {
    return this.api.post('/contatoMensagem/alterar', mensagem);
  }

  deletar(mensagem: Mensagem): Observable<any> {
    return this.api.post('/contatoMensagem/deletar', mensagem);
  }

  deletarLista(mensagens: Mensagem[]): Observable<any> {
    return this.api.post('/contatoMensagem/deletarLista', mensagens);
  }

  gerarExcel() {
    return this.api.get('/contatoMensagem/gerarExcel');
  }
  
  buscarGeral() {
    return this.api.get('/contatoGeral/buscar');
  }

  alterarGeral(contatoGeral: any) {
    return this.api.post('/contatoGeral/alterar', contatoGeral);
  }


}
