import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContatoService } from './../../services/class/contato.service';
import { Component, OnInit } from '@angular/core';
import { ContatoGeral } from 'src/app/models/contato-geral.model';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  contatoGeral: ContatoGeral = new ContatoGeral();
  buscarContatoGeralSubscription: Subscription;
  loading: boolean = false;
  phoneMask: string = '(00) 0000-00009';

  constructor(
    private contatoService: ContatoService,
    public helper: HelperService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.buscarContatoGeral();
  }

  ngOnDestroy() {
    this.buscarContatoGeralSubscription.unsubscribe();
  }

  buscarContatoGeral() {
    this.buscarContatoGeralSubscription = this.contatoService.buscarGeral()
      .subscribe((res: any) => {
        this.contatoGeral = res;
      })
  }

  alterarContatoGeral(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.contatoService.alterarGeral(this.contatoGeral)
      .subscribe((res: any) => {
        this.loading = false;
        this.alertService.success('Dados atualizado com sucesso!');
      }, (e) => {
        this.loading = false;
      });
  }

  changePhoneMask(phone) {
    let val = phone;
    if (val.replace(/\D/g, '').substring(0, 4) == "0800") {
      this.phoneMask = '0000 000 0000';
    } else {
      if (val.replace(/\D/g, '').length === 11) {
        this.phoneMask = '(00) 0 0000-0000';
      } else {
        this.phoneMask = '(00) 0000-00009';
      }
    }
  }

}
