import { DeleteDialogService } from './../../services/delete-dialog.service';
import { EventoService } from './../../services/class/evento.service';
import { Subscription } from 'rxjs';
import { Evento } from './../../models/evento.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {

  checkAll: boolean = false;
  disableDeleteButton: boolean = true;

  eventos: Evento[] = [];
  buscarEventosSubscription: Subscription;
  loading: boolean = true;

  page: number = -99;
  perPage: number = 10;
  p: number;

  filtro: string;
  filterSubscription: Subscription;

  constructor(
    private eventoService: EventoService,
    private deleteDialogService: DeleteDialogService
  ) { }

  ngOnInit() {
    this.buscarEventos();
  }

  ngOnDestroy() {
    this.buscarEventosSubscription.unsubscribe();
  }

  buscarEventos() {
    this.loading = true;
    this.buscarEventosSubscription = this.eventoService.buscar(this.page)
      .subscribe((res: any) => {
        this.loading = false;
        this.eventos = res.map((obj) => {
          obj.checked = false;
          return obj;
        });
      }, e => this.loading = false);
  }

  deletarEvento(evento: Evento) {
    this.deleteDialogService.present().then(() => {
      this.eventoService.deletar(evento).subscribe((res: any) => {
        this.eventos = new Array<Evento>();
        this.buscarEventos();
      })
    }).catch(() => { })
  }

  deletarEventos() {
    const eventos: Evento[] = this.eventos.filter(evento => evento.checked);
    if (!eventos.length) {
      return;
    }
    this.deleteDialogService.present().then(() => {
      this.eventoService.deletarLista(eventos).subscribe((res: any) => {
        this.checkAll = false;
        this.disableDeleteButton = true;
        this.eventos = new Array<Evento>();
        this.buscarEventos();
      })
    }).catch(() => { })
  }

  filtrar(ev) {
    if (this.page > 1) {
      this.page = 1;
    }
    if (ev) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.eventoService.buscar(this.page, ev.toString())
        .subscribe((res: any) => {
          this.eventos = (this.page == 1 || this.page == -99) ? res : this.eventos.concat(res);
        });
    } else {
      this.buscarEventos();
    }
  }

  marcarTodos(e) {
    e ? this.disableDeleteButton = false : this.disableDeleteButton = true;
    this.eventos = this.eventos.map(obj => {
      obj.checked = e;
      return obj;
    });
  }

  perPageChange(e) {
    this.buscarEventos();
  }

  onCheckedChange(e) {
    this.eventos.some(evento => evento.checked) ? this.disableDeleteButton = false : this.disableDeleteButton = true;
  }

}
