import { NgForm } from '@angular/forms';
import { HttpProgressEvent } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { CadastroGeral } from './../../models/cadastro-geral.model';
import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { ConfiguracaoService } from './../../services/class/configuracao.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  cadastro: CadastroGeral = new CadastroGeral();
  loading: boolean = false;

  buscarCadastroGeralSubscription: Subscription;

  file: File;
  progress: number = 0;

  constructor(
    private configuracaoService: ConfiguracaoService,
    public helper: HelperService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.buscarCadastroGeral();
  }

  buscarCadastroGeral() {
    this.buscarCadastroGeralSubscription = this.configuracaoService.buscarCadastroGeral()
      .subscribe((res: any) => {
        this.cadastro = res;
      });
  }

  alterarCadastroGeral(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.configuracaoService.alterarCadastroGeral(this.cadastro)
      .subscribe((res: any) => {
        this.loading = false;
        this.alertService.success('Cadatro geral alterado com sucesso.');
        this.buscarCadastroGeral();
      }, e => {
        this.loading = false
      });
  }

  @ViewChild('file', { static: true }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImages(this.file, '/cadastroGeral/imagem').then((res: any) => {
      this.cadastro.imagem = res.body;
    }).catch(() => {
      this.progress = 0;
      this.inputFile.nativeElement.value = '';
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.configuracaoService.postImage(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

}
