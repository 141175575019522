import { AlertService } from 'ngx-alerts';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from './../../services/helper.service';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Servico } from 'src/app/models/servico.model';
import { ServicoService } from 'src/app/services/class/servico.service';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-servicos-editar',
  templateUrl: './servicos-editar.component.html',
  styleUrls: ['./servicos-editar.component.scss']
})
export class ServicosEditarComponent implements OnInit {

  servico: Servico = new Servico();
  loading: boolean = false;
  buscarServicoSubscription: Subscription;

  file: File;
  progress: number;

  constructor(
    public helper: HelperService,
    private servicoService: ServicoService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarServico(param.id));
  }

  ngOnDestroy() {
    this.buscarServicoSubscription.unsubscribe();
  }

  buscarServico(id) {
    this.buscarServicoSubscription = this.servicoService.buscarById(id)
      .subscribe((res: any) => {
        this.servico = res;
      })
  }

  alterarServico(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;
    this.servico.slug = this.helper.convertToSlug(this.servico.titulo);

    this.servicoService.alterar(this.servico)
      .subscribe((res: any) => {
        this.submitImages(this.file, '/servico/imagemGrande?id=' + this.servico.id);
        this.loading = false;
        this.router.navigate(['/servicos/geral']).then(() => {
          this.alertService.success('Serviço alterado com sucesso.');
        })
      }, e => {
        this.alertService.danger(e.error.message);
        this.loading = false;
      });
  }

  @ViewChild('file', { static: true }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImages(this.file, '/servico/imagemGrande?id=-99').then((res: any) => {
      this.servico.imagemGrande = res.body;
    }).catch(() => {
      this.progress = 0;
      this.inputFile.nativeElement.value = '';
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.servicoService.postImage(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

}
