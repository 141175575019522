import { DeleteDialogService } from './../../services/delete-dialog.service';
import { Usuario } from './../../models/usuario.model';
import { UsuarioService } from './../../services/class/usuario.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit {

  checkAll: boolean = false;
  disableDeleteButton: boolean = true;

  page: number = -99;
  perPage: number = 10;
  p: number;

  usuarios: Array<Usuario> = new Array<Usuario>();
  buscarUsuariosSubscription: Subscription;
  loading: boolean = true;

  filtro: string;
  filterSubscription: Subscription;

  constructor(
    private usuarioService: UsuarioService,
    private deleteDialogService: DeleteDialogService
  ) { }

  ngOnInit() {
    this.buscarUsuarios();
  }

  ngOnDestroy() {
    this.buscarUsuariosSubscription.unsubscribe();
  }

  buscarUsuarios() {
    this.loading = true;
    this.buscarUsuariosSubscription = this.usuarioService.buscar(this.page)
      .subscribe((res: any) => {
        this.loading = false;
        this.usuarios = res.map((obj) => {
          obj.checked = false;
          return obj;
        });
      }, e => this.loading = false);
  }

  deletarUsuario(user: Usuario) {
    this.deleteDialogService.present().then(() => {
      this.usuarioService.deletar(user).subscribe((res: any) => {
        this.usuarios = new Array<Usuario>();
        this.buscarUsuarios();
      })
    }).catch(() => { })
  }

  deletarUsuarios() {
    const usuarios: Usuario[] = this.usuarios.filter(cliente => cliente.checked);
    if (!usuarios.length) {
      return;
    }
    this.deleteDialogService.present().then(() => {
      this.usuarioService.deletarLista(usuarios).subscribe((res: any) => {
        this.checkAll = false;
        this.disableDeleteButton = true;
        this.usuarios = new Array<Usuario>();
        this.buscarUsuarios();
      })
    }).catch(() => { })
  }

  filtrar(ev) {
    if (this.page > 1) {
      this.page = 1;
    }
    if (ev) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.usuarioService.buscar(this.page, ev.toString())
        .subscribe((res: any) => {
          this.usuarios = (this.page == 1 || this.page == -99) ? res : this.usuarios.concat(res);
        });
    } else {
      this.buscarUsuarios();
    }
  }

  marcarTodos(e) {
    e ? this.disableDeleteButton = false : this.disableDeleteButton = true;
    this.usuarios = this.usuarios.map(obj => {
      obj.checked = e;
      return obj;
    });
  }

  perPageChange(e) {
    this.buscarUsuarios();
  }

  onCheckedChange(e) {
    this.usuarios.some(usuario => usuario.checked) ? this.disableDeleteButton = false : this.disableDeleteButton = true;
  }

}
