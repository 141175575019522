import { Evento } from './../../models/evento.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  constructor(
    public api: ApiService
  ) { }

  buscar(page, filter?: string): Observable<any> {
    if (!filter) {
      return this.api.get(`/evento/buscar?numeroPagina=${page}`);
    } else {
      return this.api.get(`/evento/buscar?filtro=${filter}&numeroPagina=${page}`);
    }
  }

  buscarById(id): Observable<any> {
    return this.api.get(`/evento/buscar?id=${id}`);
  }

  inserir(evento: Evento): Observable<any> {
    return this.api.post('/evento/inserir', evento);
  }

  alterar(evento: Evento): Observable<any> {
    return this.api.post('/evento/alterar', evento);
  }

  deletar(evento: Evento): Observable<any> {
    return this.api.post('/evento/deletar', evento);
  }

  deletarLista(eventos: Evento[]): Observable<any> {
    return this.api.post('/evento/deletarLista', eventos);
  }

  postImage(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
