export class Pizza {

    id: number;
    sabor: string;
    ingredientes: string;
    observacao: string = '';
    tipo: string = 'S';
    valor: number = 0.0;
    imagem: string = '';
    situacao: string = 'A';
    [x: string]: any;
    constructor(obj?) {
        Object.assign(this);
    }
}