import { AlertService } from 'ngx-alerts';
import { ActivatedRoute, Router } from '@angular/router';
import { EventoService } from './../../services/class/evento.service';
import { HelperService } from './../../services/helper.service';
import { Evento } from './../../models/evento.model';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { HttpProgressEvent } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-eventos-editar',
  templateUrl: './eventos-editar.component.html',
  styleUrls: ['./eventos-editar.component.scss']
})
export class EventosEditarComponent implements OnInit {

  evento: Evento = new Evento();
  loading: boolean = false;
  buscarEventoSubscription: Subscription;

  file: File;
  progress: number = 0;

  constructor(
    public helper: HelperService,
    private eventoService: EventoService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarEvento(param.id));
  }

  ngOnDestroy() {
    this.buscarEventoSubscription.unsubscribe();
  }

  buscarEvento(id) {
    this.buscarEventoSubscription = this.eventoService.buscarById(id)
      .subscribe((res: any) => {
        this.evento = res;
        this.evento.data = this.helper.moment(this.evento.data).format('DD/MM/YYYY');
      })
  }

  alterarEvento(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.evento.slug = this.helper.convertToSlug(this.evento.titulo);
    this.evento.data = this.helper.moment(this.evento.data, 'DD/MM/YYYY').format();

    this.eventoService.alterar(this.evento)
      .subscribe((id: any) => {
        this.submitImages(this.file, `/evento/imagemGrande?id=${this.evento.id}`);
        this.loading = false;
        this.router.navigate(['/eventos/geral']).then(() => {
          this.alertService.success('Evento alterada com sucesso.');
        })
      }, e => {
        this.evento.data = this.helper.moment(this.evento.data).format('DD/MM/YYYY');
        this.loading = false;
      });
  }

  @ViewChild('file', { static: true }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImages(this.file, '/evento/imagemGrande?id=-99').then((res: any) => {
      this.evento.imagemGrande = res.body;
    }).catch(() => {
      this.progress = 0;
      this.inputFile.nativeElement.value = '';
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.eventoService.postImage(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

}
