import { ApiService } from './services/api.service';
import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public api: ApiService,
    public router: Router,
  ) {
    this.auth.setToken(localStorage.getItem('token_avan'));
    this.auth.user = this.auth.getUserStorage();
    this.api.httpStatus.subscribe(status => {
      if (status == 401) {
        this.router.navigate(['/login']).then(() => {
          this.auth.logout();
        })
      }
    })
  }
}
