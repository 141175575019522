import { GlobalService } from './../../services/global.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  pages: Array<{ nome: string, icon: string, router: string, openMenu?: boolean, child?: Array<any> }>;
  menuUserActive: boolean = false;
  url: string = 'https://monasteriopizzaria.com.br';

  constructor(
    private router: Router,
    public auth: AuthService,
    public global: GlobalService
  ) {
    this.pages = [
      { nome: 'home', icon: 'fas fa-home', router: '/' },
      { nome: 'banner', icon: 'fas fa-image', router: '/banner/geral', },
      { nome: 'sobre', icon: 'fas fa-question-circle', router: '/sobre/geral', },
      { nome: 'cardápio', icon: 'fas fa-pizza-slice', router: '/cardapio/geral', },
      { nome: 'serviços', icon: 'fas fa-tools', router: '/servicos/geral', },
      { 
        nome: 'eventos', 
        icon: 'fas fa-calendar-alt', 
        router: '/eventos/geral',
        openMenu: true,
        child: [
          {
            nome: 'Reservas',
            router: '/eventos/reservas'
          }
        ]
      },
      { nome: 'usuários', icon: 'fas fa-user-friends', router: '/usuarios/geral', },
      { 
        nome: 'contato', 
        icon: 'fas fa-phone', 
        router: '/contato/geral',
        openMenu: true, 
        child: [
          {
            nome: 'Mensagens',
            router: '/contato/mensagens'
          }
        ]
      },
      { nome: 'configuracoes', icon: 'fas fa-cog', router: '/configuracoes/geral' },
    ]
  }

  ngOnInit() {
  }

  openPage(page: string) {
    this.router.navigate([page]);
    this.global.sideMenuOpened = !this.global.sideMenuOpened;
  }

  openSubmenu(index) {
    this.pages[index].openMenu = !this.pages[index].openMenu;
  }

  logout() {
    this.router.navigate(['/login']).then(() => {
      this.auth.logout();
      this.global.sideMenuOpened = false;
    })
  }

}
