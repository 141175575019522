import { Subscription } from 'rxjs';
import { UsuarioService } from './../../services/class/usuario.service';
import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { Usuario } from './../../models/usuario.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-usuarios-criar',
  templateUrl: './usuarios-criar.component.html',
  styleUrls: ['./usuarios-criar.component.scss']
})
export class UsuariosCriarComponent implements OnInit {

  usuario: Usuario = new Usuario();
  loading: boolean = false;

  file: File;
  progress: number = 0;

  constructor(
    public helper: HelperService,
    public alertService: AlertService,
    public router: Router,
    public usuarioService: UsuarioService,
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  inserirUsuario(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    if (this.usuario.senha !== this.usuario.confirmar_senha) {
      this.alertService.danger('As senhas devem ser iguais.')
      return;
    }

    this.loading = true;

    this.usuarioService.inserir(this.usuario)
      .subscribe((id: any) => {
        this.submitImages(this.file, '/usuario/imagemPerfil?id=' + id);
        this.loading = false;
        this.router.navigate(['/usuarios/geral']).then(() => {
          this.alertService.success('Usuário adicionado com sucesso!');
        });
      }, (e) => {
        this.loading = false;
      });
  }

  @ViewChild('file', { static: true }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImages(this.file, '/usuario/imagemPerfil?id=-99').then((res: any) => {
      this.usuario.imagemPerfil = res.body;
    }).catch(() => {
      this.progress = 0;
      this.inputFile.nativeElement.value = '';
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.usuarioService.postImage(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

}
