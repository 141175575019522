import { Usuario } from './../../models/usuario.model';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(
    private api: ApiService
  ) { }

  buscar(page, filter?: string): Observable<any> {
    if (!filter) {
      return this.api.get('/usuario/buscar?numeroPagina=' + page);
    } else {
      return this.api.get('/usuario/buscar?filtro=' + filter + '&numeroPagina=' + page);
    }
  }

  buscarById(id): Observable<any> {
    return this.api.get('/usuario/buscar?id=' + id);
  }

  buscarByEmail(email): Observable<any> {
    return this.api.get('/usuario/buscar?email=' + email);
  }

  existeEmailCadastro(email): Observable<any> {
    return this.api.get('/usuario/existeEmailCadastrado?email=' + email);
  }

  inserir(user: Usuario): Observable<any> {
    return this.api.post('/usuario/inserir', user);
  }

  alterar(user: Usuario): Observable<any> {
    return this.api.post('/usuario/alterar', user);
  }

  deletar(user: Usuario): Observable<any> {
    return this.api.post('/usuario/deletar', user);
  }

  deletarLista(users: Usuario[]): Observable<any> {
    return this.api.post('/usuario/deletarLista', users);
  }
  
  alterarSenha(user: Usuario): Observable<any> {
    return this.api.post('/usuario/alterarSenha', user);
  }

  postImage(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
