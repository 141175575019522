import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Servico } from 'src/app/models/servico.model';

@Injectable({
  providedIn: 'root'
})
export class ServicoService {

  constructor(
    private api: ApiService
  ) { }

  buscar(page, filter?: string): Observable<any> {
    if (!filter) {
      return this.api.get('/servico/buscar?numeroPagina=' + page);
    } else {
      return this.api.get('/servico/buscar?filtro=' + filter + '&numeroPagina=' + page);
    }
  }

  buscarById(id): Observable<any> {
    return this.api.get('/servico/buscar?id=' + id);
  }

  inserir(servico: Servico): Observable<any> {
    return this.api.post('/servico/inserir', servico);
  }

  alterar(servico: Servico): Observable<any> {
    return this.api.post('/servico/alterar', servico);
  }

  deletar(servico: Servico): Observable<any> {
    return this.api.post('/servico/deletar', servico);
  }

  deletarArray(servicos: Servico[]): Observable<any> {
    return this.api.post('/servico/deletarLista', servicos);
  }

  postImage(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
