import { ApiService } from './../../services/api.service';
import { AlertService } from 'ngx-alerts';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  sugestao: string;
  loading: boolean = false;

  constructor(
    public auth: AuthService,
    public alertService: AlertService,
    public api: ApiService
  ) { }

  ngOnInit() {
  }

  enviarSugestao() {

    if (!this.sugestao) {
      this.alertService.danger('Preencha o campo para concluir a sugestão');
      return;
    }

    this.loading = true;
    this.api.get(`/sugestao/enviar?texto=${this.sugestao}`).subscribe((res: any) => {
      this.loading = false;
      this.sugestao = ''; 
      this.alertService.success('Sugestão enviada com sucesso!');
    }, e => {
      this.loading = false;
      this.alertService.danger(e.error)
    })
  }

}
