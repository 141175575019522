import { Subscription } from 'rxjs';
import { Pizza } from './../../models/pizza.model';
import { PizzaService } from './../../services/class/pizza.service';
import { DeleteDialogService } from './../../services/delete-dialog.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pizzas',
  templateUrl: './pizzas.component.html',
  styleUrls: ['./pizzas.component.scss']
})
export class PizzasComponent implements OnInit {

  checkAll: boolean = false;
  disableDeleteButton: boolean = true;

  page: number = -99;
  perPage: number = 10;
  p: number;

  pizzas: Pizza[] = [];
  buscarPizzasSubscription: Subscription;
  loading: boolean = true;

  filtro: string;
  filterSubscription: Subscription;

  constructor(
    private pizzaService: PizzaService,
    private deleteDialogService: DeleteDialogService
  ) { }

  ngOnInit() {
    this.buscarPizzas();
  }

  ngOnDestroy() {
    this.buscarPizzasSubscription.unsubscribe();
  }

  buscarPizzas() {
    this.loading = true;
    this.buscarPizzasSubscription = this.pizzaService.buscar(this.page)
      .subscribe((res: any) => {
        this.loading = false;
        this.pizzas = res.map((obj) => {
          obj.checked = false;
          return obj;
        });
      }, e => this.loading = false);
  }

  deletarPizza(pizza: Pizza) {
    this.deleteDialogService.present().then(() => {
      this.pizzaService.deletar(pizza).subscribe((res: any) => {
        this.pizzas = new Array<Pizza>();
        this.buscarPizzas();
      })
    }).catch(() => { })
  }

  deletarPizzas() {
    const pizzas: Pizza[] = this.pizzas.filter(pizza => pizza.checked);
    if (!pizzas.length) {
      return;
    }
    this.deleteDialogService.present().then(() => {
      this.pizzaService.deletarLista(pizzas).subscribe((res: any) => {
        this.checkAll = false;
        this.disableDeleteButton = true;
        this.pizzas = new Array<Pizza>();
        this.buscarPizzas();
      })
    }).catch(() => { })
  }

  filtrar(ev) {
    if (this.page > 1) {
      this.page = 1;
    }
    if (ev) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.pizzaService.buscar(this.page, ev.toString())
        .subscribe((res: any) => {
          this.pizzas = (this.page == 1 || this.page == -99) ? res : this.pizzas.concat(res);
        });
    } else {
      this.buscarPizzas();
    }
  }

  marcarTodos(e) {
    e ? this.disableDeleteButton = false : this.disableDeleteButton = true;
    this.pizzas = this.pizzas.map(obj => {
      obj.checked = e;
      return obj;
    });
  }

  perPageChange(e) {
    this.buscarPizzas();
  }

  onCheckedChange(e) {
    this.pizzas.some(pizza => pizza.checked) ? this.disableDeleteButton = false : this.disableDeleteButton = true;
  }

}
