import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { PainelAdministrativo } from 'src/app/models/painel-admistrativo.model';
import { ConfiguracaoService } from 'src/app/services/class/configuracao.service';

@Component({
  selector: 'app-configuracoes-painel',
  templateUrl: './configuracoes-painel.component.html',
  styleUrls: ['./configuracoes-painel.component.scss']
})
export class ConfiguracoesPainelComponent implements OnInit {

  painel: PainelAdministrativo = new PainelAdministrativo();
  loading: boolean = false;

  buscarPainelAdministrativoSubscription: Subscription;

  constructor(
    private configuracaoService: ConfiguracaoService,
    public helper: HelperService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.buscarPainelAdministrativo();
  }

  buscarPainelAdministrativo() {
    this.buscarPainelAdministrativoSubscription = this.configuracaoService.buscarPainelAdministrativo()
      .subscribe((res: any) => {
        this.painel = res;
      });
  }

  alterarPainelAdministrativo(f: NgForm) {
    if(f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;
    
    this.configuracaoService.alterarPainelAdministrativo(this.painel)
      .subscribe((res: any) => {
        this.loading = false;
        this.alertService.success('Painel administrativo alterado com sucesso.');
        this.buscarPainelAdministrativo();
      }, e => this.loading = false);
  }
}
