export class PainelAdministrativo {

    id: number;
    logo: string;
    titulo: string = '';
    subTitulo: string = '';
    cor: string;
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}