import { HelperService } from './../../services/helper.service';
import { ReservaService } from './../../services/class/reserva.service';
import { DeleteDialogService } from './../../services/delete-dialog.service';
import { EventoService } from './../../services/class/evento.service';
import { Subscription } from 'rxjs';
import { Reserva } from './../../models/reserva.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.scss']
})
export class ReservasComponent implements OnInit {

  checkAll: boolean = false;
  reservas: Reserva[] = [];

  page: number = -99;

  filtro: string;
  filterSubscription: Subscription;
  loading: boolean = true;

  loadingExcel: boolean = false;

  constructor(
    public reservaService: ReservaService,
    private deleteDialogService: DeleteDialogService,
    public helper: HelperService
    // private messageModalService: MessageModalService
  ) { }

  ngOnInit() {
    this.buscarReservas();
  }

  gerarExcel() {
    this.loadingExcel = true;
    this.reservaService.gerarExcel().subscribe((res:any) => {
      this.loadingExcel = false;
      window.open(res);
    }, e => this.loadingExcel = false)
  }

  buscarReservas() {
    this.loading = true;
    this.reservaService.buscar(this.page).subscribe((res: any) => {
      this.loading = false;
      this.reservas = res.map((obj) => {
        obj.checked = false;
        return obj;
      });
    }, e => this.loading = false);
  }

  deletarReserva(reserva: Reserva) {
    this.deleteDialogService.present().then(() => {
      this.reservaService.deletar(reserva).subscribe((res: any) => {
        this.reservas = new Array<Reserva>();
        this.buscarReservas();
      })
    }).catch(() => { })
  }

  deletarReservas() {
    const reservas: Reserva[] = this.reservas.filter(reserva => reserva.checked);
    if (!reservas.length) {
      return;
    }
    this.deleteDialogService.present().then(() => {
      this.reservaService.deletarLista(reservas).subscribe((res: any) => {
        this.reservas = new Array<Reserva>();
        this.buscarReservas();
      })
    }).catch(() => { })
  }

  // verMensagem(mensagem: Mensagem) {
  //   this.messageModalService.present(mensagem);
  // }

  marcarTodos(e) {
    this.reservas = this.reservas.map((obj) => {
      obj.checked = e;
      return obj;
    });
  }

  filtrar(ev) {
    if (this.page > 1) {
      this.page = 1;
    }
    if (ev) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.reservaService.buscar(this.page, ev.toString())
        .subscribe((res: any) => {
          this.reservas = (this.page == 1 || this.page == -99) ? res : this.reservas.concat(res);
        });
    } else {
      this.buscarReservas();
    }
  }

}
