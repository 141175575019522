import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// imports
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-alerts';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxCurrencyModule } from "ngx-currency";

// components
import { HeaderComponent } from './components/header/header.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { EsqueceuSenhaComponent } from './components/esqueceu-senha/esqueceu-senha.component';

// pages
import { HomeComponent } from './pages/home/home.component';
import { ContatoMensagensComponent } from './pages/contato-mensagens/contato-mensagens.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesPainelComponent } from './pages/configuracoes-painel/configuracoes-painel.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { LoginComponent } from './pages/login/login.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { EventosCriarComponent } from './pages/eventos-criar/eventos-criar.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { PizzasComponent } from './pages/pizzas/pizzas.component';
import { PizzasCriarComponent } from './pages/pizzas-criar/pizzas-criar.component';
import { PizzasEditarComponent } from './pages/pizzas-editar/pizzas-editar.component';
import { ReservasComponent } from './pages/reservas/reservas.component';
import { ServicosEditarComponent } from './pages/servicos-editar/servicos-editar.component';
import { ServicosCriarComponent } from './pages/servicos-criar/servicos-criar.component';
import { ServicosComponent } from './pages/servicos/servicos.component';
import { EventosEditarComponent } from './pages/eventos-editar/eventos-editar.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { BannerComponent } from './pages/banner/banner.component';

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: false
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ConfiguracoesComponent,
    ConfiguracoesPainelComponent,
    UsuariosComponent,
    UsuariosCriarComponent,
    UsuariosEditarComponent,
    NotFoundComponent,
    HeaderComponent,
    DeleteDialogComponent,
    LoadingComponent,
    MessageModalComponent,
    EsqueceuSenhaComponent,
    PerfilComponent,
    ContatoComponent,
    ContatoMensagensComponent,
    EventosComponent,
    EventosCriarComponent,
    EventosEditarComponent,
    ServicosComponent,
    ServicosCriarComponent,
    ServicosEditarComponent,
    SobreComponent,
    PizzasComponent,
    PizzasCriarComponent,
    PizzasEditarComponent,
    ReservasComponent,
    BannerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    AlertModule.forRoot({
      maxMessages: 5,
      timeout: 3500,
      position: 'right'
    }),
    NgSelectModule,
    CKEditorModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
