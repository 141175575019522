import { DeleteDialogService } from './../../services/delete-dialog.service';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Servico } from 'src/app/models/servico.model';
import { ServicoService } from 'src/app/services/class/servico.service';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  checkAll: boolean = false;
  disableDeleteButton: boolean = true;

  servicos: Array<Servico> = new Array<Servico>();
  buscarServicosSubscription: Subscription;
  loading: boolean = true;

  page: number = -99;
  perPage: number = 10;
  p: number;

  filtro: string;
  filterSubscription: Subscription;

  constructor(
    private servicoService: ServicoService,
    private deleteDialogService: DeleteDialogService
  ) { }

  ngOnInit() {
    this.buscarServicos();
  }

  ngOnDestroy() {
    this.buscarServicosSubscription.unsubscribe();
  }

  buscarServicos() {
    this.loading = true;
    this.buscarServicosSubscription = this.servicoService.buscar(this.page)
      .subscribe((res: any) => {
        this.loading = false;
        this.servicos = res.map((obj) => {
          obj.checked = false;
          return obj;
        });
      }, e => this.loading = false)
  }

  deletarServico(categoria: Servico) {
    this.deleteDialogService.present().then(() => {
      this.servicoService.deletar(categoria).subscribe((res: any) => {
        this.servicos = new Array<Servico>();
        this.buscarServicos();
      })
    }).catch(() => { })
  }

  deletarServicos() {
    const servicos: Servico[] = this.servicos.filter(servico => servico.checked);
    if (!servicos.length) {
      return;
    }
    this.deleteDialogService.present().then(() => {
      this.servicoService.deletarArray(servicos).subscribe((res: any) => {
        this.checkAll = false;
        this.disableDeleteButton = true;
        this.servicos = new Array<Servico>();
        this.buscarServicos();
      })
    }).catch(() => { })
  }

  filtrar(ev) {
    if (this.page > 1) {
      this.page = 1;
    }
    if (ev) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.servicoService.buscar(this.page, ev.toString())
        .subscribe((res: any) => {
          this.servicos = (this.page == 1 || this.page == -99) ? res : this.servicos.concat(res);
        });
    } else {
      this.buscarServicos();
    }
  }

  marcarTodos(e) {
    e ? this.disableDeleteButton = false : this.disableDeleteButton = true;
    this.servicos = this.servicos.map(obj => {
      obj.checked = e;
      return obj;
    });
  }

  perPageChange(e) {
    this.buscarServicos();
  }

  onCheckedChange(e) {
    this.servicos.some(servico => servico.checked) ? this.disableDeleteButton = false : this.disableDeleteButton = true;
  }

}
