import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.monasteriopizzaria.com.br';
  sideMenuOpened: boolean = false;

  constructor() { }
}
