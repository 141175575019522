import { Banner } from './../../models/banner.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  constructor(
    private api: ApiService
  ) { }

  buscar(): Observable<any> {
    return this.api.get(`/banner/buscar`);
  }

  alterar(banner: Banner): Observable<any> {
    return this.api.post(`/banner/alterar`, banner)
  }

  postImage(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
