import { BannerComponent } from './pages/banner/banner.component';
import { ReservasComponent } from './pages/reservas/reservas.component';
import { PizzasEditarComponent } from './pages/pizzas-editar/pizzas-editar.component';
import { PizzasCriarComponent } from './pages/pizzas-criar/pizzas-criar.component';
import { PizzasComponent } from './pages/pizzas/pizzas.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { ServicosEditarComponent } from './pages/servicos-editar/servicos-editar.component';
import { ServicosCriarComponent } from './pages/servicos-criar/servicos-criar.component';
import { EventosEditarComponent } from './pages/eventos-editar/eventos-editar.component';
import { EventosCriarComponent } from './pages/eventos-criar/eventos-criar.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { UsuariosEditarComponent } from './pages/usuarios-editar/usuarios-editar.component';
import { UsuariosCriarComponent } from './pages/usuarios-criar/usuarios-criar.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { ConfiguracoesPainelComponent } from './pages/configuracoes-painel/configuracoes-painel.component';
import { ConfiguracoesComponent } from './pages/configuracoes/configuracoes.component';
import { ContatoMensagensComponent } from './pages/contato-mensagens/contato-mensagens.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { IsLoggedInGuard } from './guards/is-logged-in.guard';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServicosComponent } from './pages/servicos/servicos.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [IsLoggedInGuard] },
  { path: 'contato/geral', component: ContatoComponent, canActivate: [IsLoggedInGuard] },
  { path: 'contato/mensagens', component: ContatoMensagensComponent, canActivate: [IsLoggedInGuard] },
  { path: 'configuracoes/geral', component: ConfiguracoesComponent, canActivate: [IsLoggedInGuard] },
  { path: 'configuracoes/painel-administrativo', component: ConfiguracoesPainelComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/geral', component: UsuariosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/adicionar', component: UsuariosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'usuarios/editar/:id', component: UsuariosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'banner/geral', component: BannerComponent, canActivate: [IsLoggedInGuard] },
  { path: 'sobre/geral', component: SobreComponent, canActivate: [IsLoggedInGuard] },
  { path: 'cardapio/geral', component: PizzasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'cardapio/adicionar', component: PizzasCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'cardapio/editar/:id', component: PizzasEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'eventos/geral', component: EventosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'eventos/reservas', component: ReservasComponent, canActivate: [IsLoggedInGuard] },
  { path: 'eventos/adicionar', component: EventosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'eventos/editar/:id', component: EventosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'servicos/geral', component: ServicosComponent, canActivate: [IsLoggedInGuard] },
  { path: 'servicos/adicionar', component: ServicosCriarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'servicos/editar/:id', component: ServicosEditarComponent, canActivate: [IsLoggedInGuard] },
  { path: 'perfil', component: PerfilComponent, canActivate: [IsLoggedInGuard] },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
