import { AlertService } from 'ngx-alerts';
import { ApiService } from './../../services/api.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  file: File;

  constructor(
    public auth: AuthService,
    private api: ApiService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
  }

  @ViewChild('inputFile', { static: true }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImagens();
  }

  submitImagens() {
    this.api.postFile(this.file, '/usuario/imagemPerfil?id=' + this.auth.user.id, 'image')
      .subscribe((event: HttpProgressEvent | any) => {
        if (event.type === 4) {
          this.auth.user.imagemPerfil = event.body;
          this.auth.setUser(this.auth.user);
          this.alertService.success('Arquivos enviados com sucesso');
        } else {
          let progress = Math.round((event.loaded / event.total) * 100);
          if (isNaN(progress)) {
            progress = 100;
          }
        }
      }, err => console.log(err));
  }

}
