import { NgForm } from '@angular/forms';
import { HttpProgressEvent } from '@angular/common/http';
import { BannerService } from './../../services/class/banner.service';
import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { Subscription } from 'rxjs';
import { Banner } from './../../models/banner.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  banner: Banner = new Banner();
  loading: boolean = false;

  buscarBannerSubscription: Subscription;

  file: File;
  progress: number = 0;

  constructor(
    private bannerService: BannerService,
    public helper: HelperService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.buscarBanner();
  }

  buscarBanner() {
    this.buscarBannerSubscription = this.bannerService.buscar()
      .subscribe((res: any) => this.banner = res);
  }

  alterarBanner(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.bannerService.alterar(this.banner)
      .subscribe((res: any) => {
        this.loading = false;
        this.alertService.success('Cadatro geral alterado com sucesso.');
        this.buscarBanner();
      }, e => {
        this.loading = false
      });
  }

  @ViewChild('file', { static: true }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImages(this.file, '/banner/imagem').then((res: any) => {
      this.banner.imagem = res.body;
    }).catch(() => {
      this.progress = 0;
      this.inputFile.nativeElement.value = '';
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.bannerService.postImage(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }


}
