import { AuthService } from './../../services/auth.service';
import { HelperService } from './../../services/helper.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLogin: boolean = true;
  loading: boolean = false;
  user: any = {};

  constructor(
    public helper: HelperService,
    public auth: AuthService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  login(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.auth.login(this.user).subscribe((res: any) => {
      if (res) {
        this.auth.getUser(this.user).then((r: any) => {
          if (r.solicitaAlteracao == 'S' && (this.user.senha == r.codigoRecuperacao)) {
            this.auth.isRecuperarSenha = true;
          } else {
            this.auth.setToken(btoa(this.user.email + ':' + this.user.senha));
            this.router.navigate(['/']);
          }
          this.loading = false;
          this.auth.setUser(r);
        });
      } else {
        this.loading = false;
      }
    }, e => {
      this.alertService.danger(e.error);
      this.loading = false;
    });
  }

  esqueceuSenha(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.auth.forgotPassword(this.user).then((res: any) => {
      this.alertService.success('Verifique sua caixa de entrada. E-mail enviado.');
      this.loading = false;
    }).catch((e) => {
      this.alertService.danger(e.error);
      this.loading = false;
    })
  }
}
