import { HttpProgressEvent } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { PizzaService } from './../../services/class/pizza.service';
import { Pizza } from './../../models/pizza.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pizzas-criar',
  templateUrl: './pizzas-criar.component.html',
  styleUrls: ['./pizzas-criar.component.scss']
})
export class PizzasCriarComponent implements OnInit {

  pizza: Pizza = new Pizza();
  loading: boolean = false;

  file: File;
  progress: number = 0;

  constructor(
    private pizzaService: PizzaService,
    public helper: HelperService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  inserirPizza(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.pizzaService.inserir(this.pizza)
      .subscribe((id: any) => {
        this.submitImages(this.file, `/pizza/imagem?id=${id}`);
        this.loading = false;
        this.router.navigate(['/cardapio/geral']).then(() => {
          this.alertService.success('Pizza adicionada com sucesso!');
        });
      }, e => this.loading = false);
  }

  @ViewChild('file', { static: false }) inputFile: ElementRef;
  onChangeFile(files) {
    this.file = files.item(0);
    this.submitImages(this.file, '/pizza/imagem?id=-99').then((res: any) => {
      this.pizza.imagem = res.body;
    }).catch(() => {
      this.progress = 0;
      this.inputFile.nativeElement.value = '';
    });
  }

  submitImages(file, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.pizzaService.postImage(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        }, err => reject(err));
    });
  }

}
