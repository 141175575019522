export class Servico {

    id: number;
    imagemGrande?: string = '';
    imagemPequena?: string = '';
    titulo: string;
    descricao: string;
    ordem: number = 0;
    situacao: string = 'A';
    slug: string;
    [x: string]: any;
    
    constructor(obj?) {
        Object.assign(this);
    }
}