import { Pizza } from './../../models/pizza.model';
import { ApiService } from './../api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PizzaService {

  constructor(
    public api: ApiService
  ) { }

  buscar(page, filter?: string): Observable<any> {
    if (!filter) {
      return this.api.get('/pizza/buscar?numeroPagina=' + page);
    } else {
      return this.api.get('/pizza/buscar?filtro=' + filter + '&numeroPagina=' + page);
    }
  }

  buscarById(id): Observable<any> {
    return this.api.get('/pizza/buscar?id=' + id);
  }

  buscarByEmail(email): Observable<any> {
    return this.api.get('/pizza/buscar?email=' + email);
  }

  existeEmailCadastro(email): Observable<any> {
    return this.api.get('/pizza/existeEmailCadastrado?email=' + email);
  }

  inserir(pizza: Pizza): Observable<any> {
    return this.api.post('/pizza/inserir', pizza);
  }

  alterar(pizza: Pizza): Observable<any> {
    return this.api.post('/pizza/alterar', pizza);
  }

  deletar(pizza: Pizza): Observable<any> {
    return this.api.post('/pizza/deletar', pizza);
  }

  deletarLista(pizzas: Pizza[]): Observable<any> {
    return this.api.post('/pizza/deletarLista', pizzas);
  }
  
  alterarSenha(pizza: Pizza): Observable<any> {
    return this.api.post('/pizza/alterarSenha', pizza);
  }

  postImage(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}
