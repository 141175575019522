import { Mensagem } from './../models/mensagem.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageModalService {

  visible = false;
  message: Mensagem;
  listener = new Subject<boolean>();

  constructor() { }

  present(message: Mensagem) {
    this.visible = true;
    this.message = message;
  }

  dismiss() {
    this.visible = false;
    setTimeout(() => {
      this.message = null;
    }, 3000)
  }
}
