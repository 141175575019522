export class Usuario {

    id: number = null;
    email: string;
    senha: string;
    nome: string;
    sobrenome: string;
    solicitaAlteracao: string;
    codigoRecuperacao: string;
    imagemPerfil: string;
    playerId?: string;
    situacao: string = 'A';
    [x: string]: any;

    constructor(obj?) {
        Object.assign(this);
    }
}