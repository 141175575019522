import { MessageModalService } from './../../services/message-modal.service';
import { Subscription } from 'rxjs';
import { ContatoService } from './../../services/class/contato.service';
import { DeleteDialogService } from './../../services/delete-dialog.service';
import { Component, OnInit } from '@angular/core';
import { Mensagem } from 'src/app/models/mensagem.model';

@Component({
  selector: 'app-contato-mensagens',
  templateUrl: './contato-mensagens.component.html',
  styleUrls: ['./contato-mensagens.component.scss']
})
export class ContatoMensagensComponent implements OnInit {

  checkAll: boolean = false;
  mensagens: Array<Mensagem> = Array<Mensagem>();

  page: number = -99;

  filtro: string;
  filterSubscription: Subscription;
  loading: boolean = true;

  loadingExcel: boolean = false;

  constructor(
    private contatoService: ContatoService,
    private deleteDialogService: DeleteDialogService,
    private messageModalService: MessageModalService
  ) { }

  ngOnInit() {
    this.buscarMensagens();
  }

  gerarExcel() {
    this.loadingExcel = true;
    this.contatoService.gerarExcel().subscribe((res:any) => {
      this.loadingExcel = false;
      window.open(res);
    }, e => this.loadingExcel = false)
  }

  buscarMensagens() {
    this.loading = true;
    this.contatoService.buscar(this.page).subscribe((res: any) => {
      this.loading = false;
      this.mensagens = res.map((obj) => {
        obj.checked = false;
        return obj;
      });
    }, e => this.loading = false);
  }

  deletarContato(mensagem: Mensagem) {
    this.deleteDialogService.present().then(() => {
      this.contatoService.deletar(mensagem).subscribe((res: any) => {
        this.mensagens = new Array<Mensagem>();
        this.buscarMensagens();
      })
    }).catch(() => { })
  }

  deletarContatos() {
    const mensagens: Mensagem[] = this.mensagens.filter(mensagem => mensagem.checked);
    if (!mensagens.length) {
      return;
    }
    this.deleteDialogService.present().then(() => {
      this.contatoService.deletarLista(mensagens).subscribe((res: any) => {
        this.mensagens = new Array<Mensagem>();
        this.buscarMensagens();
      })
    }).catch(() => { })
  }

  verMensagem(mensagem: Mensagem) {
    this.messageModalService.present(mensagem);
  }

  marcarTodos(e) {
    this.mensagens = this.mensagens.map((obj) => {
      obj.checked = e;
      return obj;
    });
  }

  filtrar(ev) {
    if (this.page > 1) {
      this.page = 1;
    }
    if (ev) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.contatoService.buscar(this.page, ev.toString())
        .subscribe((res: any) => {
          this.mensagens = (this.page == 1 || this.page == -99) ? res : this.mensagens.concat(res);
        });
    } else {
      this.buscarMensagens();
    }
  }

}
