import { Reserva } from './../../models/reserva.model';
import { Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReservaService {

  constructor(
    private api: ApiService
  ) { }

  buscar(page, filter?: string): Observable<any> {
    if (!filter) {
      return this.api.get('/reservaEvento/buscar?numeroPagina=' + page);
    } else {
      return this.api.get('/reservaEvento/buscar?filtro=' + filter + '&numeroPagina=' + page);
    }
  }

  deletar(reserva: Reserva): Observable<any> {
    return this.api.post('/reservaEvento/deletar', reserva);
  }

  deletarLista(reservas: Reserva[]): Observable<any> {
    return this.api.post('/reservaEvento/deletarLista', reservas);
  }

  gerarExcel() {
    return this.api.get('/reservaEvento/gerarExcel');
  }

  
}
