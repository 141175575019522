import { Usuario } from './../../models/usuario.model';
import { GlobalService } from './../../services/global.service';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'ngx-alerts';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.scss']
})
export class EsqueceuSenhaComponent implements OnInit {

  user: Usuario = new Usuario();
  loading: boolean = false;

  constructor(
    public auth: AuthService,
    public helper: HelperService,
    public alertService: AlertService,
    public http: HttpClient,
    public global: GlobalService,
    public router: Router,

  ) { }

  ngOnInit() {
  }

  recuperarSenha(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.alertService.danger('Preencha os campos obrigatórios.');
      return;
    }

    if (this.user.senha !== this.user.re_senha) {
      this.alertService.danger('As senhas não coincidem.');
      return;
    }

    this.loading = true;

    this.auth.user.senha = this.user.senha;
    this.http.post(this.global.apiUrl + '/usuario/alterarSenha', this.auth.user)
      .subscribe((res: any) => {
        if(res) {
          this.auth.login(this.auth.user).subscribe((res:any) => {
            this.auth.getUser(this.auth.user).then((r: any) => {
              this.auth.setToken(btoa(this.auth.user.email + ':' + this.auth.user.senha));
              this.auth.setUser(r);
              this.router.navigate(['/']);
              this.loading = false;
            }, e => {
              this.loading = false;
            });
          })
        } else {
          this.alertService.danger('Algo deu errado, tente novamente.');
          this.loading = false;
        }
      })
  }

}
